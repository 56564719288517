import React, { useState } from "react"
import { Section, BoxContainer, Button, ButtonLevel } from "./primitives"
import styled, { useTheme } from "styled-components"

const NewsletterContainer = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 3.75rem;
    text-transform: uppercase;
    line-height: 1;
    font-family: ${props => props.theme.fonts.heading};
    margin-bottom: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
      margin-bottom: 1rem;
      color: ${props =>
        props.theme.theme == "light"
          ? props.theme.colors.primary
          : props.theme.colors.text};
    }
  }
  p {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &:before {
      content: "";
      display: block;
      width: 1rem;
      margin-right: 0.5rem;
      height: 1rem;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS4yMTUiIGhlaWdodD0iOC4yODQiIHZpZXdCb3g9IjAgMCAxMS4yMTUgOC4yODQiPgogIDxwYXRoIGlkPSJpY29uLWNoZWNrIiBkPSJNMTQ2LjI1LDI4MC44ODRsMi45MzEsMi45MzEsNi4wMjEtNi4wMjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDUuMTE5IC0yNzYuNjYzKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzM5ODI5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMS42Ii8+Cjwvc3ZnPgo=");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  form {
    margin-top: 3.5rem;
    width: 100%;
    max-width: 36rem;

    @media screen and (min-width: 40rem) {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1rem;
    }

    @media screen and (max-width: 40rem) {
      button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }

    label {
      display: none;
    }

    input[type="email"] {
      background: transparent;
      -webkit-appearance: none;
      border: 0;
      border-bottom: 1px solid ${props => props.theme.colors.border};
      padding: 0.75rem 1rem;
      width: 100%;

      &:focus {
        outline: 0;
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`

export const NewsletterSignup: React.FC = () => {
  const [email, setEmail] = React.useState("")
  const theme = useTheme()
  const [formSent, setFormSent] = useState(null)
  const [message, setMessage] = useState(null)
  const [errors, setErrors] = useState(null)



  const handleSubmit = e => {
    e.preventDefault()
    switch ("" || null) {
      case email:
        setErrors(true)
        break
      default:
        if (
          email
        ) {
          fetch(
            "https://cms.restaurantwollegras.nl/wp-json/restaurantwollegras/v1/form-submission/?email=" +
            email ,
            {
              method: "POST",
            }
          ).then(() => {
            setFormSent(true)
          })
        } else {
          setErrors(true)
        }
        break
    }
  }

  const colorRed = "#fb4f4f"

  return (
    <Section style={{ background: theme.colors.background }}>
      <NewsletterContainer>
        <h2>
          Wollegras in je mailbox
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path
              d="M17.739 2.654C16.67 1.8 14.876 0 13 0S9.372 1.766 8.262 2.654A789.217 789.217 0 00.893 8.6 2.437 2.437 0 000 10.482v13.08A2.437 2.437 0 002.438 26h21.125A2.437 2.437 0 0026 23.563V10.482a2.437 2.437 0 00-.894-1.882 728.765 728.765 0 00-7.367-5.946zm5.824 21.721H2.438a.812.812 0 01-.813-.812V10.469a.812.812 0 01.3-.629 1313.79 1313.79 0 017.347-5.914c.895-.713 2.525-2.3 3.728-2.3s2.833 1.588 3.728 2.3a1399.5 1399.5 0 017.349 5.914.812.812 0 01.3.629v13.094a.812.812 0 01-.812.813zm-.445-10.985a.609.609 0 01-.09.858c-1.159.936-2.8 2.246-5.289 4.222-1.069.853-2.888 2.667-4.739 2.654-1.858.013-3.7-1.823-4.739-2.654a869.206 869.206 0 01-5.289-4.222.609.609 0 01-.09-.858l.256-.315a.609.609 0 01.856-.09c1.156.933 2.794 2.242 5.278 4.214.856.682 2.549 2.32 3.728 2.3 1.179.019 2.872-1.619 3.728-2.3a850.092 850.092 0 005.278-4.214.609.609 0 01.856.09z"
              fill="currentColor"
            />
          </svg>
        </h2>
        <p>
          Altijd als eerste op de hoogte van leuke acties, nieuwtjes en events
        </p>
        {formSent ? (
          <span
            className="text-green font-bold"
            dangerouslySetInnerHTML={{
              __html: "Bedankt! Het formulier is succesvol verzonden.",
            }}
          />
        ) : (

          <form onSubmit={handleSubmit} method='POST'>
          <div>
            <label>Je e-mailadres</label>
            {errors && (email === null || email === "") && (
              <span
                dangerouslySetInnerHTML={{
                  __html: "Dit veld mag niet leeg zijn",
                }}
                className="mb-2"
                style={{ color: colorRed }}
              />
            )}
            <input
              name='email'
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Je e-mailadres"
            />
          </div>
          <Button level={ButtonLevel.primary}>Aanmelden</Button>
        </form>
        )}
      </NewsletterContainer>
    </Section>
  )
}

export default NewsletterSignup
